const projectsArr = [
  {
    projectName: 'Spotifyre',
    projectDesc: 'Playlist collaboration and song sharing made easy through Spotify\'s API!',
    projectLink: 'https://spotifyre-manager.netlify.app/',
    projectRepo: 'https://github.com/natekchua/Spotifyre',
    builtWith:   'React.js, TypeScript, Node.js, PostgresQL',
    img:         'https://images.unsplash.com/photo-1514525253161-7a46d19cd819?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80'
  },
  {
    projectName: 'NBA Shooter\'s Shoot',
    projectDesc: 'An interactive data visualization of 2020 NBA Player Stats.',
    projectLink: 'https://nba-shooters-shoot.netlify.app/',
    projectRepo: 'https://github.com/natekchua/NBA-Shooters-Shoot',
    builtWith:   'React.js, Chart.js',
    img:         'https://images.unsplash.com/photo-1494199505258-5f95387f933c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80'
  },
  {
    projectName: 'Nate\'s Chat Room',
    projectDesc: 'Simple Chat Room Application.',
    projectLink: 'https://nates-chat-room.netlify.app/',
    projectRepo: 'https://github.com/natekchua/ChatApp',
    builtWith:   'React.js, Node.js, Express, Socket.io.',
    img:         'https://images.unsplash.com/photo-1444210971048-6130cf0c46cf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80'
  },
  {
    projectName: 'SushiMe',
    projectDesc: 'A fun and informative page about my favorite food, Sushi!',
    projectLink: 'https://natekchua.github.io/SushiMe/dist/index.html',
    projectRepo: 'https://github.com/natekchua/SushiMe',
    builtWith:   'HTML, CSS',
    img:         'https://images.unsplash.com/photo-1607301405418-780ee5e6dd10?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1147&q=80'
  },
  {
    projectName: 'Minesweeper',
    projectDesc: 'Web version of Minesweeper with varying difficulties.',
    projectLink: 'https://nates-minesweeper.netlify.app/',
    projectRepo: 'https://github.com/natekchua/Minesweeper',
    builtWith:   'React.js',
    img:         'https://images.unsplash.com/photo-1591631368886-bb78ae96ee13?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
  },
  {
    projectName: 'Carol\'s Portfolio',
    projectDesc: 'A personal portfolio for the lovely Carol Tran.',
    projectLink: 'https://caroltran.me/',
    projectRepo: 'https://github.com/natekchua/CT-Portfolio-v2',
    builtWith:   'React.js',
    img:         'https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80'
  },
  {
    projectName: 'MNIST & Heart Data Models',
    projectDesc: 'Machine Learning Models built & trained using TensorFlow.',
    projectLink: null,
    projectRepo: 'https://github.com/natekchua/TensorFlow-Modelling',
    builtWith:   'Python, TensorFlow',
    img:         'https://images.unsplash.com/photo-1569396116180-210c182bedb8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1049&q=80'
  },
  {
    projectName: 'PlanHarvest',
    projectDesc: 'A web application focused on improving Farm Management.',
    projectLink: 'https://www.planharvest.com/',
    projectRepo: 'https://pastebin.pl/view/0081d045',
    builtWith:   'React.js, Node.js, PostgresQL',
    img:         'https://images.unsplash.com/photo-1530836369250-ef72a3f5cda8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
  },
  {
    projectName: 'Techr',
    projectDesc: 'A Java application focused on browsing tech products with ease.',
    projectLink: null,
    projectRepo: 'https://github.com/natekchua/Techr-v2',
    builtWith:   'Java',
    img:         'https://images.unsplash.com/photo-1480506132288-68f7705954bd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1093&q=80'
  },
  {
    projectName: 'Reflective Object Distributor',
    projectDesc: 'Program that serializes object(s) and sends it over a network.',
    projectLink: null,
    projectRepo: 'https://github.com/natekchua/Reflective-Object-Distributor',
    builtWith:   'Java',
    img:         'https://images.unsplash.com/photo-1564676677001-92e8f1a0df30?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80'
  },
  {
    projectName: 'Reflective Object Inspector',
    projectDesc: 'Program that completely introspects an object at run-time.',
    projectLink: null,
    projectRepo: 'https://github.com/natekchua/Reflective-Object-Inspector',
    builtWith:   'Java',
    img:         'https://images.unsplash.com/photo-1520500807606-4ac9ae633574?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
  },
  {
    projectName: 'My HTML & CSS Projects',
    projectDesc: 'A collection of HTML & CSS Projects I\'ve done.',
    projectLink: null,
    projectRepo: 'https://pastebin.pl/view/5d27ec68',
    builtWith:   'HTML, CSS',
    img:         'https://images.unsplash.com/photo-1534235792096-4f5a022d4c7c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
  }
]

module.exports = projectsArr;
