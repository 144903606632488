import React from 'react';
import './Home.css';
import { ArrowDownOutlined } from '@ant-design/icons';
import {
  Button
} from 'antd';
import { 
  Link 
} from 'react-scroll';

import 'antd/dist/antd.css';

function Home () {
  return (
    <div className='container' id='home'>
      <div className='home-class'> 
        <h1> Hello! I'm Nate. 😁</h1>
        <h2>Software Developer 👨🏽‍💻, Hooper 🏀, & Sushi Enthusiast 🍣</h2>
        <div className='btn-container mt-20'>
          <Link
            activeClass='active'
            to='about'
            spy={true}
            smooth={true}
            offset={-10}
            duration={500}
          >
            <Button type='primary' className='btn' style={{ marginLeft: 0 }} icon={<ArrowDownOutlined />}>A little more about myself</Button>
          </Link>
          <Link
            activeClass='active'
            to='projects'
            spy={true}
            smooth={true}
            offset={-15}
            duration={500}
          >
            <Button type='primary' className='btn' icon={<ArrowDownOutlined />}>Projects of mine</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
