import NavBar from './Components/NavBar/NavBar';
import Home from './Components/Home/Home';
import AboutMe from './Components/AboutMe/AboutMe';
import Projects from './Components/Projects/Projects';

function App () {
  return (
    <div className='container'>
      <NavBar />
      <Home />
      <AboutMe />
      <Projects />
    </div>
  );
}

export default App;
