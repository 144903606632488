import React, { useState } from 'react'
import {
  Layout,
  Menu,
  Affix
} from 'antd'
import { HomeOutlined, SmileOutlined, CodeSandboxOutlined } from '@ant-design/icons';
import { Link } from 'react-scroll'

const { Header } = Layout

const NavBar = () => {
  const [page, setPage] = useState(1)
  return (
    <Affix>
      <Header>
        <Menu theme='dark' mode='horizontal' selectedKeys={[page]} defaultSelectedKeys={[page]}>
          <Menu.Item style={{ color: '#ffffff' }} key='1'>
            <Link
              activeClass='active'
              to='home'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setPage('1')}
            >
              <HomeOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Menu.Item>
          <Menu.Item style={{ color: '#ffffff' }} key='2'>
            <Link
              activeClass='active'
              to='about'
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
              onClick={() => setPage('2')}
            >
              <SmileOutlined style={{ fontSize: '18px' }} />
          </Link>
          </Menu.Item>
          <Menu.Item style={{ color: '#ffffff' }} key='3'>
            <Link
              activeClass='active'
              to='projects'
              spy={true}
              smooth={true}
              offset={-15}
              duration={500}
              onClick={() => setPage('3')}
            >
              <CodeSandboxOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Menu.Item>
        </Menu>
      </Header>
    </Affix >
  );
}

export default NavBar;
