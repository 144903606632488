import React from 'react';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { 
  Link 
} from 'react-scroll';

import './AboutMe.css';

function AboutMe () {
  return (
    <div className='container' id='about'>
      <div className='about-class'>
        <h2>Bachelor of Science in Computer Science from University of Calgary 🎓</h2>
        <h2>Software Developer for Enverus ⚡</h2>
        <h2>JavaScript (React, Node), TypeScript, Python, MongoDB, SQL, & learning more 🐱‍💻</h2>
        <h2>
          Feel free to checkout my
          <a href='https://www.linkedin.com/in/nathan-chua-3815ba140/' target='_blank' rel='noreferrer'>
            <span className='socials'> LinkedIn</span>
          </a> - and my 
          <a href='https://github.com/natekchua' target='_blank' rel='noreferrer'>
            <span style={{ color: '#1890FF !important' }}> Github</span>
          </a>, too!
        </h2>
        <div className='btn-container mt-20'>
          <Link
            activeClass='active'
            to='home'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Button type='primary' className='btn' icon={<ArrowUpOutlined />}>Back to the top</Button>
          </Link>
          <Link
            activeClass='active'
            to='projects'
            spy={true}
            smooth={true}
            offset={-15}
            duration={500}
          >
            <Button type='primary' className='btn' icon={<ArrowDownOutlined />}>Projects of mine</Button>
          </Link>
        </div>
      </div>

    </div>
  );
}

export default AboutMe;
