import React, { useState, useEffect } from 'react';
import { CodeSandboxOutlined } from '@ant-design/icons';
import projectsArr from './projectsConfig';

import './Projects.css'
import ProjectItem from './ProjectItem';

function Projects () {
  const [projectItems, setProjectItems] = useState([])
  useEffect(() => {
    let projItems = projectsArr.map((p, i) => {
      return (
        <ProjectItem
          key={i}
          projectName={p.projectName}
          projectDesc={p.projectDesc}
          projectLink={p.projectLink}
          projectRepo={p.projectRepo}
          builtWith={p.builtWith}
          img={p.img}
        />
      )
    });
    setProjectItems(projItems)
  }, []) 

  return (
    <div className='container' id='projects'>
      <div className='projects-class'>
        <h1>Check out my projects <CodeSandboxOutlined spin size='large' /></h1>
        <div className='project-items'>
          {projectItems}
        </div>
      </div>
    </div>
  )
}

export default Projects
