import React from 'react';
import { Button } from 'antd';
import { LinkOutlined, GithubOutlined } from '@ant-design/icons';

import './Projects.css';

function ProjectItem (props) {
  const { projectName, projectDesc, projectLink, projectRepo, builtWith, img } = props;
  return (
    <div className='item'>
      <div className='item-image'>
        <img src={img} alt={projectName} style={{ height: '100%' }} />
      </div>
      <div className='item-text'>
        <div className='item-text-wrap'>
          <h2 className='item-text-title'>{projectName}</h2>
          <p className='item-text-category'>{projectDesc}</p>
          <div className='project-btn-container'>
            {projectLink && <Button className='btn' type='ghost' icon={<LinkOutlined />} size='large' shape='circle' href={projectLink} target='_blank' />}
            {projectRepo && <Button className='btn' type='ghost' icon={<GithubOutlined />} size='large' shape='circle' href={projectRepo} target='_blank' />}
          </div>
          <h5>{builtWith}</h5>
        </div>
      </div>
    </div> 
  );
}

export default ProjectItem;
